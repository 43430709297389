var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.$vuetify.breakpoint.smAndDown)?_c('v-navigation-drawer',{attrs:{"absolute":"","right":"","width":_vm.drawerRightWidth,"permanent":_vm.contentDrawer,"bottom":_vm.$vuetify.breakpoint.smAndDown},on:{"transitionend":function($event){if($event.target !== $event.currentTarget)return null;return _vm.mapInvalidate({ pan: true })}},model:{value:(_vm.contentDrawer),callback:function ($$v) {_vm.contentDrawer=$$v},expression:"contentDrawer"}},[_c('div',{staticClass:"nav-wrapper overflow-hidden"},[(_vm.currentUUID)?_c('TabBar',{staticClass:"flex-grow-0"}):_vm._e(),(_vm.currentUUID)?_c('content-windows',{staticClass:"overflow-y-auto"}):_vm._e(),_c('keep-alive',[(_vm.currentUUID)?_c('AudioPlayer',{staticClass:"mt-auto"}):_vm._e()],1)],1)]):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('div',{ref:"bottomSheet",staticClass:"nav-wrapper",class:!_vm.dragging ? 'expand-transition' : null,staticStyle:{"position":"fixed","bottom":"0","cursor":"ns-resize"},style:({ height: _vm.bottomHeight - _vm.allowedDiff + 'px' }),on:{"transitionend":function($event){if($event.target !== $event.currentTarget)return null;{
        _vm.updateBottomHeight(_vm.bottomHeight - _vm.allowedDiff);
        _vm.mapInvalidate({ pan: true });
      }}}},[_c('keep-alive',[(_vm.currentUUID)?_c('AudioPlayer',{directives:[{name:"touch",rawName:"v-touch",value:({
          start: () => {
            this.dragging = true;
          },
          move: (e) => _vm.resize(e),
          end: () => {
            this.dragging = false;
            this.$nextTick(() => {
              this.updateBottomHeight(this.bottomHeight - this.allowedDiff);
              this.mapInvalidate({ pan: true });
            });
          },
        }),expression:"{\n          start: () => {\n            this.dragging = true;\n          },\n          move: (e) => resize(e),\n          end: () => {\n            this.dragging = false;\n            this.$nextTick(() => {\n              this.updateBottomHeight(this.bottomHeight - this.allowedDiff);\n              this.mapInvalidate({ pan: true });\n            });\n          },\n        }"}],on:{"toggleContentDrawer":function($event){return _vm.toggleContentDrawer()}}}):_vm._e()],1),_c('content-windows',{staticClass:"overflow-y-auto"}),(_vm.currentUUID)?_c('TabBar',{staticClass:"mt-auto flex-grow-0"}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }